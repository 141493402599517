const { MerkleTree } = require("merkletreejs");
const keccak256 = require("keccak256");

const TEAM_ADDRESSES = [
    "0x35F98A55C4A13fB98F41C5c14124DCa3623297df",
    "0xf8db0a904ab52c49491a16431cd19698f512a68a",
    "0x207C9cf6838A7FF98814398D0a7aa9Fe00672541",
];
const hashedTeam = TEAM_ADDRESSES.map((address) => keccak256(address));
const TEAM_MERKLE_TREE = new MerkleTree(hashedTeam, keccak256, {
    sortPairs: true,
});

const ALLOWLIST_ADDRESSES = [
    "0x006A5E9657c82C57f90caA035DF57B4Fbce9cdd2",
    "0xf2b7f8ea8de38e19433b2812752cd0892c96b9ac",
    "0xf1285258b1f90bf942472c821c8b2381129868e9",
    "0xf033e473215d72aeb5f327798a5eb4c363a755a5",
    "0xd8cbb1f93f9356a9260c7f454f09ca9130b6fd8a",
    "0xd80b5cd3ffdd88b82ba47fbde6d8ebd4f75b44db",
    "0xd53ef52d8c56f7f46c6ecc35c9c773635c8da721",
    "0xd309d2a99898d23de869b6db994dc765a31583d7",
    "0xd2e7625d3a5d3a017dec2cc0b7dc7785294978e3",
    "0xcce237dbeda874458c4d265c188c5956ca80ee26",
    "0xc2c4609b1aeec0a34e9ac5b7cf991d78033896e8",
    "0xc2861d891f1be6f21a6da8406b9302ac2a269ab4",
    "0xc2188ee69329527a49ae9310535489ebb1f1dd89",
    "0xba6a94f8e9595e275915e90cdd6e0e91bad773a5",
    "0xb994a3dabd78e2d68861f7c51c16a4b852d96791",
    "0xaa4ea7de31fa57cf051cac897291f53489be47a8",
    "0xaa458b0795f6268b5963106892e9d0977d70cf37",
    "0xa3f5fd1ae3a6a69d0158e6735f2705ee520416c5",
    "0xa2d1902da4552c27fae8151fae0124fba8d39354",
    "0xa1a540dd6d597cde10b0dc89838357f21172e55a",
    "0x9fcd43b3899ce7836366dc41a72e7003da2d895c",
    "0x9aa7474983584885eff33b064d78ea766a49b727",
    "0x91940263467b86d12b901d689918b34a3df31859",
    "0x862357b9c85876110f7e85202d937a470c2e2fbd",
    "0x85a091830609454acc8234e63fa2225bd6168a18",
    "0x846978259adc90d5be03c7f2921af8b58857a1d6",
    "0x786824de170d9d4a98728c1d5634543cddd50ab6",
    "0x77f73448d4b2ace0eaba17aa2acf24378f7e8939",
    "0x77209a11dab7ef5b655457f0cf1e0bfb64de0626",
    "0x73bafd0b00ed096abe14f7d752a7f3259ac3e9e3",
    "0x6a329d74b030d0c4dd1669eafeaeecc534803e40",
    "0x67fa73e8cc5f67ab017f68dcf213bb74dcbf9e6e",
    "0x6351b68517bd899b44d0ee8301d14302cbfdf59c",
    "0x5dcbaa8b85912e17b695c49aca7677b22ebd41dd",
    "0x55997fc6d2fa070178d5374c3d92441a838c206f",
    "0x530b74fa34d26bd30cb8f11482ec63f4f2b983d4",
    "0x4ccd1c4de64b023289561852d4e56cca5b867ebf",
    "0x462bbe829cc3b4818c98c58fcfa7fad7215571e6",
    "0x43cbefc7b3e000dc7b01c958b26dabf9d3b67198",
    "0x4289d975197b95e83314480fe2308dfbd94798ff",
    "0x3da8c6a28a1b8ad5d084453fb4c33059e4636db3",
    "0x3baafa7fa884d6fe4bf306bf1a04b664741190a4",
    "0x3552a12c60bb0e9d85d84cb1c04e112934e3a502",
    "0x34d7e8c3083e7ef20a5f500a1ce32ac48fbd84e9",
    "0x2c940ecbac8a2fdc510746d69e52516e2b51378c",
    "0x28fcde4edcdc1272a977e43b96772408935369e3",
    "0x265cc7f759d97e3fb3f76679f359ec11ad4d9b31",
    "0x0dc3a48511eb63213f12109dca755a5dcd9cc6a3",
    "0x0ac28e7f11cd5106a8b2606dd916ee652fb49f83",
    "0x09b334d395678b57a96f6c221d25bfc2b0ef1012",
    "0x02622d426c72671296dec7fc2c6c69ec056f253c",
];

const hashedAllowlist = ALLOWLIST_ADDRESSES.map((address) => keccak256(address));
const ALLOWLIST_MERKLE_TREE = new MerkleTree(hashedAllowlist, keccak256, {
    sortPairs: true,
});



const WAITLIST_ADDRESSES = [
    "0x9097c7830106f875415eC6D3F1B74fb2e7c1188f",
    "0xf9e4a219f9982a76804f0111219a04bb96901ece",
    "0xf8350ef53cbc4fc3484998b1fadcad918350a370",
    "0xf72a512bfdcc30a85fe32a3116c27d5df318b9fb",
    "0xf56c1fbca7b9fc14f46bc078dd69c10fee3cbc0b",
    "0xf30acfe9ef1415489e85c646a336a6a290ec493c",
    "0xf2e4a05cbae83fb3173becee7a31686e8a6ae3ce",
    "0xf1b2e3d1efa47a7105d99651808f6963798a6d27",
    "0xef17b1f696cd61acaa10004ce4063b621a425d9d",
    "0xe8e7ff5ec4a73229e427ad08da49c0b95e40b778",
    "0xe80df4e57fb37c325486401a99ec980a637d3d42",
    "0xe78e9ca12338d070f3c93a1535a1d79c498f07aa",
    "0xe66883bafec274cb9e7bc22fb5ed2ccb5b9af7e2",
    "0xe41b294178e708495c4a4107e563001f554d91a1",
    "0xe366ce32d07275e09cfafdef99face484be63965",
    "0xdf967c1a2cbe28eecfd7034cea7d52706d02e424",
    "0xdf3cd57ca50981dc543e5ecb4997a6558ec9a830",
    "0xdc37ee2371a096e595af959e0f979aa43411bf3f",
    "0xdadbbba238ca368af6a120a53537191ef4d5d64f",
    "0xcdd465b324976ce2b172944c9e9afc1622766335",
    "0xc446cd3b4ea3f5bb7ad422515e909f60859a0b19",
    "0xc3b105ec7278f3c00547ab96a9883a61c440482a",
    "0xbcde9ad56e71d96c4026eee2bb29edc97553cc32",
    "0xbc648a2124989fece0230d689827b3a4197bbfa7",
    "0xb793d4eb177627720d2e885fcca532dcb67b2b50",
    "0xb1faddeca6cbccd536355a4efe0e2d5517a1f04f",
    "0xb18df5be6a4f2b14099455b430a7846c80529951",
    "0xad38714548f01424c2a00eb8b900bfd63af4b3b7",
    "0xaab86239606e1755abde3c0cce833340b2a88c72",
    "0xa9e6eb4694294fd1e577bd8ea131b9bf5ff3b183",
    "0x971eb21675d78f9c3a2d85ac3c0a951167092e75",
    "0x952ac02f116f4b22b9168921f82e8b42aa535811",
    "0x91fec5dba1a39e511024c414505b7b3e49898b23",
    "0x904d4b2fe14793ce9f853654627bd01235fabe89",
    "0x8f5917b6ac2198563c848f0a6b486dfc8f0bef86",
    "0x8e54da0b3f02e48b4dd751d8ce26bb1f689ce6cf",
    "0x8ddc5f7833f600afe9febb82cd1af21ab565dc84",
    "0x8dd988e0df6030e28be38cad90e346c422f36a2c",
    "0x8af37571caa67cabfef40ce9a8bc2d1626eb65b1",
    "0x8a2ca64e7a3a9d008346fd7dd67db61f6eb42a51",
    "0x7cfce02171ed77742c033f067ad660b9964f002c",
    "0x7ac6336d56d340528c0efe3f1234ae1c5a90d506",
    "0x798713488e94d344df1717357e35ce324b645e9c",
    "0x797e2ac3ac01488788daedc1f644e23a6133d3d4",
    "0x743c3e157013043807bb29f4bb3b9db0046242c9",
    "0x6fd496aecfe6d392ad34322372b861473eaa2105",
    "0x6f4a16ab8385f6ca15fb1427c837c019e1a054ab",
    "0x68d4b8467051f47a42ddafe0c7783956bb865b1c",
    "0x668ca185c3cdfa625115b0a5b0d35bcdadfe0327",
    "0x62c9e9a412000b5f82208868217f4ea1e76d5408",
    "0x5f5fa7f6a53a4f1a95dcc53de93fe3732cd199d4",
    "0x5bf0df7c0058e54af324db699f734d7dd92c5d99",
    "0x570f646e0d9af8b3f477c8d68393156873f54ff2",
    "0x4fe26b77bc2d9aff19731d1e6f55ed79b0c70662",
    "0x4d0852fca6ab6f5d6cf13604611a3ee2b0b020c6",
    "0x4bdc3b5d5004a1409dd62b23a744cfeab3a0ce91",
    "0x49fe7bb490730ad666d12cd16142bb9ed3675cf4",
    "0x49131f1a71414cc29fe5a8d408dd6aafe0e48c4d",
    "0x4760715483275eb5c76b9f80bf8ba1a9b0e8bafd",
    "0x417e9763aed7c76fb078ad3ba9a9ee881b93b527",
    "0x38b78f650e29eedc1f8dad939d7368361e845d11",
    "0x389ef0f330bd149486d44c2171dfa5be2ba3f48d",
    "0x316a35ebc7bfb945ab84e8bf6167585602306192",
    "0x311bdab096705b57f564d7d6df728fc9c9b40b4d",
    "0x2fe0e7d1beb1c652055243b4952c4c4af4bcef6e",
    "0x2e039299209de1419280127d6823a3db1e7e1ee6",
    "0x289b2d5777e8b3642224446d9578ad2fb41b3aad",
    "0x234B6D1d8E3A59dc6cdD050C1C7752a2440cD9B7",
    "0x22621b2764f211607238576692923cc3a42da1ef",
    "0x221698db85dae3e38fdff8e39bc0b0c66e611121",
    "0x1321bc6ffa79ab03ed1f773504340428f660025c",
    "0x0bc7815f29ede74742d7377821506f705cf0d809",
    "0x09bbc99a38c995aea1924a5606cf6dc2262bd995",
    "0x060fb9f0d0529a80f5fb94ecad98df7df99ecb0a",
    "0x0559d1d33db1a17c4324a41b20368b94ca071598",
    "0x014c96f838e13c31192201924512b0d4850033e2",
];

const hashedWaitlist = WAITLIST_ADDRESSES.map((address) => keccak256(address));
const WAITLIST_MERKLE_TREE = new MerkleTree(hashedWaitlist, keccak256, {
    sortPairs: true,
});

    console.log("ALLOWLIST: ","0x" + ALLOWLIST_MERKLE_TREE.getRoot().toString("hex"));
    console.log("WAITLIST: ","0x" + WAITLIST_MERKLE_TREE.getRoot().toString("hex"));


const CONTRACT_ABI = [
    {
      "inputs": [
        {
          "internalType": "string",
          "name": "_placeholderURI",
          "type": "string"
        },
        {
          "internalType": "bytes32",
          "name": "_teamMerkleRoot",
          "type": "bytes32"
        },
        {
          "internalType": "bytes32",
          "name": "_allowlistMerkleRoot",
          "type": "bytes32"
        },
        {
          "internalType": "bytes32",
          "name": "_waitlistMerkleRoot",
          "type": "bytes32"
        }
      ],
      "stateMutability": "nonpayable",
      "type": "constructor"
    },
    {
      "inputs": [],
      "name": "ApprovalCallerNotOwnerNorApproved",
      "type": "error"
    },
    {
      "inputs": [],
      "name": "ApprovalQueryForNonexistentToken",
      "type": "error"
    },
    {
      "inputs": [],
      "name": "ApproveToCaller",
      "type": "error"
    },
    {
      "inputs": [],
      "name": "BalanceQueryForZeroAddress",
      "type": "error"
    },
    {
      "inputs": [],
      "name": "MintERC2309QuantityExceedsLimit",
      "type": "error"
    },
    {
      "inputs": [],
      "name": "MintToZeroAddress",
      "type": "error"
    },
    {
      "inputs": [],
      "name": "MintZeroQuantity",
      "type": "error"
    },
    {
      "inputs": [],
      "name": "OwnerQueryForNonexistentToken",
      "type": "error"
    },
    {
      "inputs": [],
      "name": "OwnershipNotInitializedForExtraData",
      "type": "error"
    },
    {
      "inputs": [],
      "name": "TransferCallerNotOwnerNorApproved",
      "type": "error"
    },
    {
      "inputs": [],
      "name": "TransferFromIncorrectOwner",
      "type": "error"
    },
    {
      "inputs": [],
      "name": "TransferToNonERC721ReceiverImplementer",
      "type": "error"
    },
    {
      "inputs": [],
      "name": "TransferToZeroAddress",
      "type": "error"
    },
    {
      "inputs": [],
      "name": "URIQueryForNonexistentToken",
      "type": "error"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "address",
          "name": "owner",
          "type": "address"
        },
        {
          "indexed": true,
          "internalType": "address",
          "name": "approved",
          "type": "address"
        },
        {
          "indexed": true,
          "internalType": "uint256",
          "name": "tokenId",
          "type": "uint256"
        }
      ],
      "name": "Approval",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "address",
          "name": "owner",
          "type": "address"
        },
        {
          "indexed": true,
          "internalType": "address",
          "name": "operator",
          "type": "address"
        },
        {
          "indexed": false,
          "internalType": "bool",
          "name": "approved",
          "type": "bool"
        }
      ],
      "name": "ApprovalForAll",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "uint256",
          "name": "fromTokenId",
          "type": "uint256"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "toTokenId",
          "type": "uint256"
        },
        {
          "indexed": true,
          "internalType": "address",
          "name": "from",
          "type": "address"
        },
        {
          "indexed": true,
          "internalType": "address",
          "name": "to",
          "type": "address"
        }
      ],
      "name": "ConsecutiveTransfer",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "address",
          "name": "previousOwner",
          "type": "address"
        },
        {
          "indexed": true,
          "internalType": "address",
          "name": "newOwner",
          "type": "address"
        }
      ],
      "name": "OwnershipTransferred",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "address",
          "name": "from",
          "type": "address"
        },
        {
          "indexed": true,
          "internalType": "address",
          "name": "to",
          "type": "address"
        },
        {
          "indexed": true,
          "internalType": "uint256",
          "name": "tokenId",
          "type": "uint256"
        }
      ],
      "name": "Transfer",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": false,
          "internalType": "bytes32",
          "name": "_old",
          "type": "bytes32"
        },
        {
          "indexed": false,
          "internalType": "bytes32",
          "name": "_new",
          "type": "bytes32"
        }
      ],
      "name": "UpdatedAllowlist",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "_old",
          "type": "uint256"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "_new",
          "type": "uint256"
        }
      ],
      "name": "UpdatedAllowlistMintAmount",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": false,
          "internalType": "bool",
          "name": "_old",
          "type": "bool"
        },
        {
          "indexed": false,
          "internalType": "bool",
          "name": "_new",
          "type": "bool"
        }
      ],
      "name": "UpdatedAllowlistStatus",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": false,
          "internalType": "bytes32",
          "name": "_old",
          "type": "bytes32"
        },
        {
          "indexed": false,
          "internalType": "bytes32",
          "name": "_new",
          "type": "bytes32"
        }
      ],
      "name": "UpdatedTeamList",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": false,
          "internalType": "bool",
          "name": "_old",
          "type": "bool"
        },
        {
          "indexed": false,
          "internalType": "bool",
          "name": "_new",
          "type": "bool"
        }
      ],
      "name": "UpdatedTeamStatus",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": false,
          "internalType": "bytes32",
          "name": "_old",
          "type": "bytes32"
        },
        {
          "indexed": false,
          "internalType": "bytes32",
          "name": "_new",
          "type": "bytes32"
        }
      ],
      "name": "UpdatedWaitlist",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "_old",
          "type": "uint256"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "_new",
          "type": "uint256"
        }
      ],
      "name": "UpdatedWaitlistMintAmount",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": false,
          "internalType": "bool",
          "name": "_old",
          "type": "bool"
        },
        {
          "indexed": false,
          "internalType": "bool",
          "name": "_new",
          "type": "bool"
        }
      ],
      "name": "UpdatedWaitlistStatus",
      "type": "event"
    },
    {
      "inputs": [],
      "name": "allowedMintsPerAllowlistAddress",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "allowedMintsPerWaitlistAddress",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "bytes32[]",
          "name": "merkleProof",
          "type": "bytes32[]"
        },
        {
          "internalType": "uint64",
          "name": "amount",
          "type": "uint64"
        }
      ],
      "name": "allowlistMint",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "allowlistStatus",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "ownerAddress",
          "type": "address"
        }
      ],
      "name": "amountMinted",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "to",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "tokenId",
          "type": "uint256"
        }
      ],
      "name": "approve",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "owner",
          "type": "address"
        }
      ],
      "name": "balanceOf",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "collectionSize",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "contractURI",
      "outputs": [
        {
          "internalType": "string",
          "name": "",
          "type": "string"
        }
      ],
      "stateMutability": "pure",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "disableAllowlist",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "disableTeam",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "disableWaitlist",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "enableAllowlist",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "enableWaitlist",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "tokenId",
          "type": "uint256"
        }
      ],
      "name": "getApproved",
      "outputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "getCollectionSize",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "getMintStatus",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "owner",
          "type": "address"
        },
        {
          "internalType": "address",
          "name": "operator",
          "type": "address"
        }
      ],
      "name": "isApprovedForAll",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "mintPrice",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "mintingFee",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "name",
      "outputs": [
        {
          "internalType": "string",
          "name": "",
          "type": "string"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "owner",
      "outputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "tokenId",
          "type": "uint256"
        }
      ],
      "name": "ownerOf",
      "outputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "renounceOwnership",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "from",
          "type": "address"
        },
        {
          "internalType": "address",
          "name": "to",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "tokenId",
          "type": "uint256"
        }
      ],
      "name": "safeTransferFrom",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "from",
          "type": "address"
        },
        {
          "internalType": "address",
          "name": "to",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "tokenId",
          "type": "uint256"
        },
        {
          "internalType": "bytes",
          "name": "_data",
          "type": "bytes"
        }
      ],
      "name": "safeTransferFrom",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "bytes32",
          "name": "_merkleRoot",
          "type": "bytes32"
        }
      ],
      "name": "setAllowlistMerkleRoot",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "operator",
          "type": "address"
        },
        {
          "internalType": "bool",
          "name": "approved",
          "type": "bool"
        }
      ],
      "name": "setApprovalForAll",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "string",
          "name": "baseURI",
          "type": "string"
        }
      ],
      "name": "setBaseURI",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "bytes32",
          "name": "_merkleRoot",
          "type": "bytes32"
        }
      ],
      "name": "setTeamlistMerkleRoot",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "bytes32",
          "name": "_merkleRoot",
          "type": "bytes32"
        }
      ],
      "name": "setWaitlistMerkleRoot",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "bytes4",
          "name": "interfaceId",
          "type": "bytes4"
        }
      ],
      "name": "supportsInterface",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "symbol",
      "outputs": [
        {
          "internalType": "string",
          "name": "",
          "type": "string"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "bytes32[]",
          "name": "merkleProof",
          "type": "bytes32[]"
        },
        {
          "internalType": "uint64",
          "name": "amount",
          "type": "uint64"
        }
      ],
      "name": "teamMint",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "teamStatus",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "tokenId",
          "type": "uint256"
        }
      ],
      "name": "tokenURI",
      "outputs": [
        {
          "internalType": "string",
          "name": "",
          "type": "string"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "totalSupply",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "from",
          "type": "address"
        },
        {
          "internalType": "address",
          "name": "to",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "tokenId",
          "type": "uint256"
        }
      ],
      "name": "transferFrom",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "newOwner",
          "type": "address"
        }
      ],
      "name": "transferOwnership",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "_amount",
          "type": "uint256"
        }
      ],
      "name": "updateAllowlistMintAmount",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "_amount",
          "type": "uint256"
        }
      ],
      "name": "updateWaitlistMintAmount",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "bytes32[]",
          "name": "merkleProof",
          "type": "bytes32[]"
        },
        {
          "internalType": "uint64",
          "name": "amount",
          "type": "uint64"
        }
      ],
      "name": "waitlistMint",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "waitlistStatus",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    }
  ]

const CONTRACT_ADDRESS = "0x0486F811cf3558817A90a606E19D891cCbF64f0C";

module.exports = {
    TEAM_ADDRESSES: TEAM_ADDRESSES,
    TEAM_MERKLE_TREE: TEAM_MERKLE_TREE,
    ALLOWLIST_ADDRESSES: ALLOWLIST_ADDRESSES,
    ALLOWLIST_MERKLE_TREE: ALLOWLIST_MERKLE_TREE,
    WAITLIST_ADDRESSES: WAITLIST_ADDRESSES,
    WAITLIST_MERKLE_TREE: WAITLIST_MERKLE_TREE,
    CONTRACT_ABI: CONTRACT_ABI,
    CONTRACT_ADDRESS: CONTRACT_ADDRESS,
};
