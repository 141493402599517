// import MerkleTree from "merkletreejs";
// import keccak256 from "keccak256";
export const {
    TEAM_ADDRESSES,
    TEAM_MERKLE_TREE,
    ALLOWLIST_ADDRESSES,
    ALLOWLIST_MERKLE_TREE,
    WAITLIST_ADDRESSES,
    WAITLIST_MERKLE_TREE,
    CONTRACT_ABI,
    CONTRACT_ADDRESS,
} = require("../shared-constants");
